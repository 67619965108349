.light, .dark, .flag-pl, .flag-uk {
  cursor: pointer;
  height: 2.6rem;
  width: 4.875rem;
  border-radius: .6rem;
  position: absolute;
}

.zoom-link, .main-nav__link:link, .main-nav__link:visited {
  color: inherit;
  font-family: inherit;
  text-decoration: none;
}

.opening__text-box, .slider__btn-container, .list__item, .header__item, .content-wrapper, .form__head, .form__btn-container, .footer {
  align-items: center;
  display: flex;
}

.section-about, .section-services, .article-transport, .article-inspection, .section-gallery, .section-contact, .section-form {
  width: 100%;
  padding: 8rem 0;
  overflow: hidden;
}

@media only screen and (width <= 59.375em) {
  .section-about, .section-services, .article-transport, .article-inspection, .section-gallery, .section-contact, .section-form {
    padding: 6rem 0;
  }
}

@media only screen and (width <= 46.875em) {
  .section-about, .section-services, .article-transport, .article-inspection, .section-gallery, .section-contact, .section-form {
    padding: 4rem 0;
  }
}

.light, .flag-pl {
  top: .5rem;
  left: .5rem;
}

.dark, .flag-uk {
  top: .5rem;
  right: .5rem;
}

.slide {
  width: 100%;
  height: 100%;
}

.heading-featured-in, .content-heading, .form__number {
  letter-spacing: .07rem;
  font-weight: 700;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  font-size: 62.5%;
  line-height: 1.15;
  overflow-x: hidden;
}

@media only screen and (width <= 90em) {
  html {
    font-size: 53.125%;
  }
}

@media only screen and (width <= 85.375em) {
  html {
    font-size: 46.875%;
  }
}

body {
  box-sizing: border-box;
}

strong {
  font-size: 1.8rem;
  font-weight: 600;
}

main {
  display: block;
}

::selection {
  color: #fff;
  background-color: #de3434;
}

:focus {
  outline-offset: .3rem;
  border-radius: .6rem;
  outline: .5rem outset #b22a2a;
}

@media only screen and (width <= 85.375em) {
  :focus {
    outline: .4rem outset #b22a2a;
  }
}

body {
  color: #1e1e1e;
  letter-spacing: .03rem;
  font-family: Roboto Slab;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  position: relative;
}

.heading-primary {
  color: #fff;
  letter-spacing: -.1rem;
  font-family: sans-serif;
  font-size: 5.8rem;
  font-weight: 700;
  line-height: 1.2;
}

@media only screen and (width <= 90em) {
  .heading-primary {
    font-size: 5.4rem;
  }
}

@media only screen and (width <= 46.875em) {
  .heading-primary {
    font-size: 4.8rem;
  }
}

@media only screen and (width <= 28.125em) {
  .heading-primary {
    font-size: 4.6rem;
  }
}

.heading-secondary {
  margin-bottom: 4rem;
  font-size: 4.4rem;
}

@media only screen and (width <= 46.875em) {
  .heading-secondary {
    font-size: 4rem;
  }
}

@media only screen and (width <= 35em) {
  .heading-secondary {
    margin-bottom: 3rem;
  }
}

.heading-teritary {
  margin-bottom: 4rem;
  font-size: 3.2rem;
}

@media only screen and (width <= 46.875em) {
  .heading-teritary {
    font-size: 3rem;
  }
}

.heading-featured-in {
  font-size: 2.2rem;
}

@media only screen and (width <= 46.875em) {
  .heading-featured-in {
    font-size: 2.2rem;
  }
}

.content-heading {
  backface-visibility: hidden;
  font-size: 2.2rem;
}

.content-text {
  color: #595959;
  backface-visibility: hidden;
  font-size: 1.8rem;
}

.paragraph {
  font-size: 1.8rem;
  line-height: 1.8;
}

.u-center-text {
  text-align: center !important;
}

.u-big-heading-gap {
  margin-bottom: 8rem !important;
}

.u-md-heading-gap {
  margin-bottom: 6rem !important;
}

@media only screen and (width <= 35em) {
  .u-md-heading-gap {
    margin-bottom: 4rem;
  }
}

.u-low-heading-gap {
  margin-bottom: 2.4rem !important;
}

.u-no-offset:focus {
  outline-offset: 0 !important;
}

.u-center-form {
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}

.u-open {
  height: 33.4rem !important;
  padding: 2.3rem !important;
}

@media only screen and (width <= 35em) {
  .u-dn {
    display: none;
  }
}

.zoom-link {
  backface-visibility: hidden;
  transition: all .2s ease-in-out;
}

.zoom-link, .zoom-link--empty, .zoom-link--noheading {
  flex-direction: column;
  gap: 1.4rem;
  display: flex;
}

.zoom-link__heading {
  letter-spacing: .07rem;
  font-size: 2.2rem;
  font-weight: 700;
}

.zoom-link__text {
  color: #595959;
  font-size: 1.8rem;
}

.zoom-link:hover, .zoom-link:active {
  transform: scale(1.05);
}

.logo {
  width: 47.3rem;
  height: 8.7rem;
  transition: all 1.6s cubic-bezier(.68, -.6, .32, 1.6);
}

@media only screen and (width <= 28.125em) {
  .logo {
    width: 42.57rem;
    height: 7.93rem;
  }
}

.logo-link:hover .logo {
  transform: rotateY(360deg);
}

.opening {
  letter-spacing: .075rem;
  color: #595959;
  grid-column: 1 / 3;
  padding-top: 1.6rem;
  font-size: 1.8rem;
}

.opening__text-box {
  gap: .8rem;
}

@media only screen and (width <= 28.125em) {
  .opening__text-box {
    gap: .4rem;
  }
}

.opening__time {
  padding-left: .8rem;
}

@media only screen and (width <= 28.125em) {
  .opening__time {
    padding-left: 0;
  }
}

.red {
  color: #e60000;
}

.green {
  color: green;
}

.switch-label {
  height: 3.6rem;
  width: 12rem;
  display: inline-block;
  position: relative;
}

.switch-label input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  background-color: #d5d5d5;
  border-radius: .6rem;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.switch-slider:before {
  cursor: pointer;
  content: "";
  height: 3rem;
  width: 5.275rem;
  z-index: 9;
  background-color: #222;
  border-radius: .6rem;
  transition: all .4s;
  position: absolute;
  bottom: .3rem;
  left: .3rem;
}

input:checked + .switch-slider:before {
  transform: translateX(6.125rem);
}

.slider {
  width: 100vw;
  height: 60rem;
  position: relative;
  overflow: hidden;
}

@media only screen and (width <= 90em) {
  .slider {
    height: 50rem;
  }
}

.slider__btn-container {
  height: 5.2rem;
  justify-content: space-between;
}

@media only screen and (width <= 46.875em) {
  .slider__btn-container {
    justify-content: flex-start;
    gap: 2rem;
  }
}

.slider__btn {
  color: #1e1e1e;
  height: 8.4rem;
  width: 4.2rem;
  cursor: pointer;
  background: #ffffffb3;
  border: none;
  border-radius: .6rem;
  font-family: inherit;
  font-size: 4.2rem;
  line-height: 0;
  position: absolute;
  top: 50%;
}

@media only screen and (width <= 46.875em) {
  .slider__btn {
    height: 6.72rem;
    width: 3.36rem;
  }
}

.slider__btn--left {
  left: 4.8rem;
  transform: translate(-50%, -50%);
}

@media only screen and (width <= 46.875em) {
  .slider__btn--left {
    left: 3.2rem;
  }
}

.slider__btn--right {
  right: 4.8rem;
  transform: translate(50%, -50%);
}

@media only screen and (width <= 46.875em) {
  .slider__btn--right {
    right: 3.2rem;
  }
}

.slider__text-box {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.slide {
  background-position: center;
  background-size: cover;
  padding: 6rem 9.6rem;
  transition: transform 1s;
  position: absolute;
  top: 0;
}

@media only screen and (width <= 46.875em) {
  .slide {
    padding: 5.2rem 6.4rem 6.4rem;
  }
}

@media only screen and (width <= 28.125em) {
  .slide {
    padding: 3.2rem 6.4rem 6.4rem;
  }
}

.slide--1 {
  background-image: url("slider-1.8bebf4ec.webp");
}

.slide--1 .slider__text-box {
  max-width: 56.5rem;
}

@media only screen and (width <= 46.875em) {
  .slide--1 .slider__text-box {
    max-width: 45rem;
  }
}

@media only screen and (width <= 28.125em) {
  .slide--1 .slider__text-box {
    max-width: 36rem;
  }
}

.slide--2 {
  background-image: url("slider-2.483d45c3.webp");
}

.slide--2 .slider__text-box {
  max-width: 54rem;
}

@media only screen and (width <= 46.875em) {
  .slide--2 .slider__text-box {
    max-width: 44rem;
  }
}

@media only screen and (width <= 28.125em) {
  .slide--2 .slider__text-box {
    max-width: 26rem;
  }
}

.slide--3 {
  background-image: url("slider-3.43fb6076.webp");
}

.slide--3 .slider__text-box {
  max-width: 55.5rem;
}

@media only screen and (width <= 46.875em) {
  .slide--3 .slider__text-box {
    max-width: 38rem;
  }
}

.dots {
  display: flex;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.dots__dot {
  opacity: .7;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
  background-color: #b9b9b9;
  border: none;
  border-radius: 50%;
  margin-right: 1.75rem;
  transition: all .5s;
  box-shadow: 0 .6rem 1.5rem #fff3;
}

.dots__dot:last-child {
  margin: 0;
}

.dots__dot--active {
  opacity: 1;
  background-color: #fff;
}

.btn, .btn:link, .btn:visited {
  cursor: pointer;
  border: none;
  border-radius: .6rem;
  padding: 1.4rem 2.8rem;
  font-family: inherit;
  font-size: 1.9rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .16s ease-in-out;
  display: block;
}

@media only screen and (width <= 46.875em) {
  .btn, .btn:link, .btn:visited {
    height: -moz-max-content;
    height: max-content;
    width: -moz-min-content;
    width: min-content;
    padding: 1rem 2rem;
    font-size: 1.9rem;
    line-height: 1.3;
  }
}

.btn--full:link, .btn--full:visited {
  color: #fff;
  background-color: #222;
}

.btn--full:hover, .btn--full:active {
  font-size: 2.2rem;
}

@media only screen and (width <= 46.875em) {
  .btn--full:hover, .btn--full:active {
    font-size: 2rem;
  }
}

.btn--outline:link, .btn--outline:visited {
  color: #1e1e1e;
  background-color: #fff;
}

.btn--outline:hover, .btn--outline:active {
  font-size: 2.2rem;
}

@media only screen and (width <= 46.875em) {
  .btn--outline:hover, .btn--outline:active {
    font-size: 2rem;
  }
}

.logos {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1.6rem 3.2rem;
  display: flex;
}

.yamaha {
  height: 6rem;
  width: 16.655rem;
}

@media only screen and (width <= 59.375em) {
  .yamaha {
    height: 4.5rem;
    width: 12.5226rem;
    order: 1;
  }
}

@media only screen and (width <= 28.125em) {
  .yamaha {
    order: 1;
  }
}

.suzuki {
  height: 3.6rem;
  width: 18.435rem;
}

@media only screen and (width <= 59.375em) {
  .suzuki {
    height: 2.7rem;
    width: 13.861rem;
    order: 2;
  }
}

@media only screen and (width <= 28.125em) {
  .suzuki {
    order: 2;
  }
}

.honda {
  height: 6rem;
  width: 7.2rem;
}

@media only screen and (width <= 59.375em) {
  .honda {
    height: 4.5rem;
    width: 5.41354rem;
    order: 3;
  }
}

@media only screen and (width <= 28.125em) {
  .honda {
    order: 7;
  }
}

.kawasaki {
  height: 5rem;
  width: 8.4rem;
}

@media only screen and (width <= 59.375em) {
  .kawasaki {
    height: 3.75rem;
    width: 6.316rem;
    order: 4;
  }
}

@media only screen and (width <= 28.125em) {
  .kawasaki {
    order: 5;
  }
}

.aprilia {
  height: 6rem;
  width: 5.575rem;
}

@media only screen and (width <= 59.375em) {
  .aprilia {
    height: 4.5rem;
    width: 4.192rem;
    order: 5;
  }
}

@media only screen and (width <= 28.125em) {
  .aprilia {
    order: 6;
  }
}

.bmw {
  height: 6rem;
  width: 6rem;
}

@media only screen and (width <= 59.375em) {
  .bmw {
    height: 4.5rem;
    width: 4.5rem;
    order: 6;
  }
}

@media only screen and (width <= 28.125em) {
  .bmw {
    order: 9;
  }
}

.can-am {
  height: 3.6rem;
  width: 18.635rem;
}

@media only screen and (width <= 59.375em) {
  .can-am {
    height: 2.7rem;
    width: 14.013rem;
    order: 4;
  }
}

@media only screen and (width <= 28.125em) {
  .can-am {
    order: 7;
  }
}

.piaggio {
  height: 6rem;
  width: 19.425rem;
}

@media only screen and (width <= 59.375em) {
  .piaggio {
    height: 4.5rem;
    width: 14.6053rem;
    order: 7;
  }
}

@media only screen and (width <= 28.125em) {
  .piaggio {
    order: 7;
  }
}

.kymco {
  height: 4.4rem;
  width: 24.546rem;
}

@media only screen and (width <= 59.375em) {
  .kymco {
    height: 3.3rem;
    width: 18.456rem;
    order: 9;
  }
}

@media only screen and (width <= 28.125em) {
  .kymco {
    order: 10;
  }
}

.polaris {
  height: 3.6rem;
  width: 26.55rem;
}

@media only screen and (width <= 59.375em) {
  .polaris {
    height: 2.7rem;
    width: 19.963rem;
    order: 10;
  }
}

@media only screen and (width <= 28.125em) {
  .polaris {
    order: 12;
  }
}

.ktm {
  height: 5rem;
  width: 12.438rem;
}

@media only screen and (width <= 59.375em) {
  .ktm {
    height: 3.75rem;
    width: 9.352rem;
    order: 11;
  }
}

@media only screen and (width <= 28.125em) {
  .ktm {
    order: 8;
  }
}

.husqvarna {
  height: 4.4rem;
  width: 20.46rem;
}

@media only screen and (width <= 59.375em) {
  .husqvarna {
    height: 3.3rem;
    width: 15.345rem;
    order: 12;
  }
}

@media only screen and (width <= 28.125em) {
  .husqvarna {
    order: 11;
  }
}

.img-box {
  border-radius: .6rem;
  outline: .5rem outset #b7b7b7;
  overflow: hidden;
}

.img-box__img {
  border-radius: .6rem;
}

.img-inset-zoom {
  transition: all .4s;
}

.img-inset-zoom:hover {
  transform: scale(1.07);
}

.list {
  flex-direction: column;
  gap: 1.6rem;
  list-style: none;
  display: flex;
}

.list__item {
  gap: 1.6rem;
  font-size: 2rem;
  line-height: 1.2;
}

.list__icon {
  width: 3rem;
  height: 3rem;
  color: #000;
}

.nest {
  padding: .4rem 0 0 2.9rem;
}

.nest .list__item {
  gap: 1.2rem;
  font-size: 1.8rem;
}

.nest .list__icon {
  width: 2.4rem;
  height: 2.4rem;
}

.gslide {
  transition: transform 1s;
  position: absolute;
  top: 10.4rem;
}

.gslider__btn {
  color: #e4e4e4;
  height: 8.4rem;
  width: 4.2rem;
  cursor: pointer;
  background: #000000b3;
  border: none;
  border-radius: .6rem;
  font-family: inherit;
  font-size: 4.2rem;
  line-height: 0;
  position: absolute;
  top: 41rem;
}

@media only screen and (width <= 68.75em) {
  .gslider__btn {
    top: 65.05rem;
  }
}

@media only screen and (width <= 46.875em) {
  .gslider__btn {
    height: 6.72rem;
    width: 3.36rem;
  }
}

@media only screen and (width <= 35em) {
  .gslider__btn {
    top: 52.9rem;
  }
}

.gslider__btn--left {
  z-index: 99;
  left: -3.95rem;
  transform: translate(-50%, -50%);
}

@media only screen and (width <= 35em) {
  .gslider__btn--left {
    left: -2.47rem;
  }
}

.gslider__btn--right {
  z-index: 99;
  right: -3.95rem;
  transform: translate(50%, -50%);
}

@media only screen and (width <= 35em) {
  .gslider__btn--right {
    right: -2.47rem;
  }
}

.gdots {
  display: flex;
  position: absolute;
  bottom: -2.65rem;
  left: 50%;
  transform: translateX(-50%);
}

.gdots__dot {
  opacity: .7;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
  background-color: #b9b9b9;
  border: none;
  border-radius: 50%;
  margin-right: 1.75rem;
  transition: all .5s;
  box-shadow: 0 .6rem 1.5rem #0000001a;
}

.gdots__dot--active {
  opacity: 1;
  background-color: #595959;
}

.gdots__dot:last-child {
  margin: 0;
}

.header {
  width: 100vw;
  flex-direction: column;
  display: flex;
}

.header__content-wrapper {
  width: 100%;
  background-color: #fff;
  justify-content: space-around;
  align-items: center;
  padding: 2.4rem 4.8rem;
  display: flex;
}

@media only screen and (width <= 68.75em) {
  .header__content-wrapper {
    grid-template-columns: 1fr 2fr 1fr;
    padding-top: 1.6rem;
    display: grid;
  }
}

@media only screen and (width <= 28.125em) {
  .header__content-wrapper {
    padding: 1.6rem 2.66rem 2.66rem 2rem;
  }
}

.header__content-heading, .header__content-text {
  text-align: center;
}

.header__items {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

@media only screen and (width <= 68.75em) {
  .header__items {
    width: 80rem;
    grid-area: 2 / 1 / 3 / span 3;
    justify-self: center;
  }
}

@media only screen and (width <= 46.875em) {
  .header__items {
    width: 60rem;
    -moz-column-gap: 3rem;
    grid-template-columns: 28rem 17rem;
    justify-content: center;
    justify-items: self-start;
    column-gap: 3rem;
  }
}

@media only screen and (width <= 28.125em) {
  .header__items {
    width: 40rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
  }
}

@media only screen and (width <= 46.875em) {
  .header__items--f {
    grid-template-columns: 20rem 17rem;
  }
}

@media only screen and (width <= 28.125em) {
  .header__items--f {
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }
}

.header__item {
  justify-content: center;
  padding: 1.6rem 0;
}

.header__logo-box {
  align-self: start;
}

@media only screen and (width <= 68.75em) {
  .header__logo-box {
    grid-column: 2 / span 1;
    padding: 0;
  }
}

.header__checkboxes {
  justify-content: space-around;
  display: flex;
}

@media only screen and (width <= 46.875em) {
  .headerR__email {
    order: 3;
  }

  .headerR__email--f {
    grid-column: 1 / span 2;
    justify-self: start;
  }

  .headerR__open-hours {
    order: 1;
  }

  .headerR__call-now {
    order: 2;
    justify-self: center;
  }

  .headerR__opening {
    order: 5;
    grid-column: 1 / span 2;
  }

  .headerR__checkboxes {
    flex-direction: column;
    order: 4;
    justify-self: center;
  }
}

.box-open-hours {
  text-align: center;
}

@media only screen and (width <= 46.875em) {
  .box-open-hours {
    text-align: left;
  }
}

.main-nav {
  height: 5.2rem;
  background-color: #222;
  padding: 1.6rem;
}

@media only screen and (width <= 46.875em) {
  .main-nav {
    height: 6.4rem;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: relative;
  }
}

@media only screen and (width <= 59.375em) {
  .main-nav__emp {
    display: none;
  }
}

.main-nav__list {
  grid-template-columns: 1.5fr 2fr 2fr 2fr 2fr 2fr 2fr 1.5fr;
  grid-column-start: 2;
  align-content: center;
  justify-items: center;
  row-gap: 0;
  list-style-type: none;
  display: grid;
}

@media only screen and (width <= 59.375em) {
  .main-nav__list {
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr;
  }
}

@media only screen and (width <= 46.875em) {
  .main-nav__list {
    width: 100vw;
    z-index: 9999;
    backface-visibility: hidden;
    height: 0;
    background-color: #222;
    flex-direction: column;
    align-items: center;
    row-gap: 3.6rem;
    margin-top: -6.3rem;
    padding: 0;
    transition: all .25s ease-in-out;
    display: flex;
    position: absolute;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%, 50%);
  }
}

.main-nav__link:link, .main-nav__link:visited {
  color: #fff;
  font-size: 1.8rem;
  transition: all .17s ease-in-out;
}

.main-nav__link:hover, .main-nav__link:active {
  font-size: 2rem;
}

.main-nav__link--cta:link, .main-nav__link--cta:visited {
  background-color: #000;
  border-radius: .6rem;
  padding: .6rem;
  font-size: 1.9rem;
  font-weight: 600;
}

.main-nav__link--cta:hover, .main-nav__link--cta:active {
  font-size: 2.1rem;
  box-shadow: 0 1.2rem 3.2rem #0000001a;
}

@media only screen and (width <= 46.875em) {
  .main-nav__home {
    height: 3.6rem;
    width: 3.6rem;
    z-index: 999999;
    position: relative;
  }
}

.main-nav__home-box {
  display: none;
}

@media only screen and (width <= 46.875em) {
  .main-nav__home-box {
    height: 3.6rem;
    width: 3.6rem;
    z-index: 999999;
    display: block;
    position: relative;
  }

  .main-nav__menu {
    z-index: 9999999;
    width: 3.7rem;
    height: 2.8rem;
    cursor: pointer;
    transition: all .5s ease-in-out;
    position: relative;
    transform: rotate(0);
  }

  .main-nav__menu-box {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .main-nav__menu span {
    height: .3rem;
    width: 100%;
    opacity: 1;
    background: #fff;
    border-radius: .6rem;
    transition: all .25s ease-in-out;
    display: block;
    position: absolute;
    left: 0;
    transform: rotate(0);
  }

  .main-nav__menu span:first-child {
    top: 0;
  }

  .main-nav__menu span:nth-child(2), .main-nav__menu span:nth-child(3) {
    top: 1.35rem;
  }

  .main-nav__menu span:nth-child(4) {
    top: 2.7rem;
  }

  .main-nav__menu.open span:first-child {
    width: 0%;
    top: 1.35rem;
    left: 50%;
  }

  .main-nav__menu.open span:nth-child(2) {
    transform: rotate(45deg);
  }

  .main-nav__menu.open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  .main-nav__menu.open span:nth-child(4) {
    width: 0%;
    top: 2.7rem;
    left: 50%;
  }
}

.sticky .main-nav {
  width: 100%;
  height: 5.2rem;
  z-index: 99999;
  background-color: #222222eb;
  padding: 1.6rem;
  position: fixed;
  top: 0;
  bottom: 0;
  box-shadow: inset 0 -.1rem .2rem #b7b7b7;
}

@media only screen and (width <= 46.875em) {
  .sticky .main-nav {
    height: 6.4rem;
    background-color: #222;
    box-shadow: inset 0 -.1rem .2rem #222;
  }
}

.sticky .header__content-wrapper {
  margin-bottom: 5.2rem;
}

@media only screen and (width <= 46.875em) {
  .sticky .header__content-wrapper {
    margin-bottom: 6.4rem;
  }

  .sticky .main-nav__list .u-open {
    border-bottom: .1rem solid #b7b7b7;
  }
}

.content-wrapper {
  justify-content: space-between;
  gap: 8rem;
  transition: transform 1s;
}

@media only screen and (width <= 59.375em) {
  .content-wrapper {
    grid-template-columns: 1fr min-content 1fr;
    justify-items: center;
    gap: 4rem;
    display: grid;
  }
}

@media only screen and (width <= 35em) {
  .content-wrapper {
    gap: 2.66rem 0;
  }
}

.box {
  transition: all .4s ease-in-out;
}

@media only screen and (width <= 59.375em) {
  .about__centered {
    grid-template-columns: 1fr 60rem 1fr;
    display: grid;
  }
}

@media only screen and (width <= 35em) {
  .about__centered {
    grid-template-columns: 1fr 55rem 1fr;
  }
}

@media only screen and (width <= 28.125em) {
  .about__centered {
    grid-template-columns: 1fr 40rem 1fr;
  }
}

@media only screen and (width <= 59.375em) {
  .about__full {
    grid-area: 2 / 1 / span 1 / span 3;
  }

  .about__heading {
    grid-column: 2 / span 1;
  }
}

.about__text-box {
  width: 52rem;
  font-size: 1.8rem;
  line-height: 1.8;
  display: block;
}

@media only screen and (width <= 59.375em) {
  .about__text-box {
    width: 58rem;
    grid-column: 2 / span 1;
  }
}

@media only screen and (width <= 35em) {
  .about__text-box {
    width: 55rem;
  }
}

@media only screen and (width <= 28.125em) {
  .about__text-box {
    width: 40rem;
  }
}

.about__img-box, .about__img {
  width: 60rem;
  height: 29.65rem;
  border-radius: .6rem;
}

@media only screen and (width <= 59.375em) {
  .about__img-box, .about__img {
    width: 50rem;
    height: 24.7083rem;
    order: -1;
    grid-column: 2 / span 1;
  }
}

@media only screen and (width <= 35em) {
  .about__img-box, .about__img {
    width: 40rem;
    height: 19.7667rem;
  }
}

.content-wrapper.services-content-wrapper {
  justify-content: center;
  gap: 16rem;
  padding-top: 2rem;
}

@media only screen and (width <= 59.375em) {
  .content-wrapper.services-content-wrapper {
    gap: 4rem;
  }
}

@media only screen and (width <= 35em) {
  .content-wrapper.services-content-wrapper {
    row-gap: 2.66rem;
    padding-top: 0;
  }
}

@media only screen and (width <= 59.375em) {
  .services__centered {
    grid-template-columns: 1fr 60rem 1fr;
    display: grid;
  }
}

@media only screen and (width <= 35em) {
  .services__centered {
    grid-template-columns: 1fr 55rem 1fr;
  }
}

@media only screen and (width <= 28.125em) {
  .services__centered {
    grid-template-columns: 1fr 40rem 1fr;
  }
}

@media only screen and (width <= 59.375em) {
  .services__full {
    grid-area: 2 / 1 / span 1 / span 3;
  }

  .services__heading {
    grid-column: 2 / span 1;
  }
}

.services__img-box, .services__img {
  width: 35rem;
  height: 52.495rem;
}

@media only screen and (width <= 59.375em) {
  .services__img-box, .services__img {
    order: -1;
    grid-column: 2 / span 1;
  }
}

@media only screen and (width <= 46.875em) {
  .services__img-box, .services__img {
    width: 30rem;
    height: 45rem;
  }
}

@media only screen and (width <= 59.375em) {
  .services__text-box {
    width: 60rem;
    grid-column: 2 / span 1;
    justify-content: center;
    display: flex;
  }
}

@media only screen and (width <= 35em) {
  .services__text-box {
    width: 55rem;
  }
}

@media only screen and (width <= 28.125em) {
  .services__text-box {
    width: 40rem;
  }
}

@media only screen and (width <= 59.375em) {
  .transport__centered {
    grid-template-columns: 1fr 60rem 1fr;
    display: grid;
  }
}

@media only screen and (width <= 35em) {
  .transport__centered {
    grid-template-columns: 1fr 55rem 1fr;
  }
}

@media only screen and (width <= 28.125em) {
  .transport__centered {
    grid-template-columns: 1fr 40rem 1fr;
  }
}

@media only screen and (width <= 59.375em) {
  .transport__full {
    grid-area: 2 / 1 / span 1 / span 3;
  }

  .transport__heading {
    grid-column: 2 / span 1;
  }
}

.transport__text-box {
  width: 65.45rem;
  font-size: 1.8rem;
  line-height: 1.8;
}

@media only screen and (width <= 59.375em) {
  .transport__text-box {
    width: 60rem;
    grid-column: 2 / span 1;
    justify-content: center;
    display: flex;
  }
}

@media only screen and (width <= 35em) {
  .transport__text-box {
    width: 55rem;
  }
}

@media only screen and (width <= 28.125em) {
  .transport__text-box {
    width: 40rem;
  }
}

.transport__img-box, .transport__img {
  height: 35rem;
  width: 46.55rem;
}

@media only screen and (width <= 59.375em) {
  .transport__img-box, .transport__img {
    height: 30rem;
    width: 40rem;
    order: -1;
    grid-column: 2 / span 1;
  }

  .inspection__centered {
    grid-template-columns: 1fr 60rem 1fr;
    display: grid;
  }
}

@media only screen and (width <= 35em) {
  .inspection__centered {
    grid-template-columns: 1fr 55rem 1fr;
  }
}

@media only screen and (width <= 28.125em) {
  .inspection__centered {
    grid-template-columns: 1fr 40rem 1fr;
  }
}

@media only screen and (width <= 59.375em) {
  .inspection__full {
    grid-area: 2 / 1 / span 1 / span 3;
  }

  .inspection__heading {
    grid-column: 2 / span 1;
  }
}

.inspection__img-box, .inspection__img {
  height: 30rem;
  width: 41rem;
}

@media only screen and (width <= 59.375em) {
  .inspection__img-box, .inspection__img {
    order: -1;
    grid-column: 2 / span 1;
  }
}

.inspection__text-box {
  width: 67.649rem;
  font-size: 1.8rem;
  line-height: 1.8;
}

@media only screen and (width <= 59.375em) {
  .inspection__text-box {
    width: 60rem;
    grid-column: 2 / span 1;
    justify-content: center;
    display: flex;
  }
}

@media only screen and (width <= 35em) {
  .inspection__text-box {
    width: 55rem;
  }
}

@media only screen and (width <= 28.125em) {
  .inspection__text-box {
    width: 40rem;
  }
}

.gallery__container {
  width: 126rem;
  height: 64.4rem;
  margin: 0 auto;
  position: relative;
}

@media only screen and (width <= 68.75em) {
  .gallery__container {
    height: 113rem;
    width: 62rem;
  }
}

@media only screen and (width <= 35em) {
  .gallery__container {
    height: 113rem;
    width: 30rem;
  }
}

.gallery__content-wrapper {
  flex-direction: column;
  display: flex;
}

.gallery__img-box {
  -moz-column-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.8rem 2rem;
  display: grid;
}

@media only screen and (width <= 68.75em) {
  .gallery__img-box {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (width <= 35em) {
  .gallery__img-box {
    grid-template-columns: 1fr;
  }
}

.gallery__img {
  width: 30rem;
  height: 22.5rem;
  border-radius: .6rem;
  outline: .5rem outset #b7b7b7;
  overflow: hidden;
}

.gallery__img:hover {
  cursor: pointer;
}

.gallery__modal {
  width: 0;
  position: relative;
  left: 50%;
}

.modal__img {
  width: 90rem;
  height: 67.5rem;
  z-index: 999;
  border-radius: .6rem;
  outline: .5rem outset #b7b7b7;
  position: relative;
  top: -57.05rem;
  left: -45rem;
}

@media only screen and (width <= 90em) {
  .modal__img {
    width: 73.15rem;
    height: 55rem;
    top: -50.8rem;
    left: -36.575rem;
  }
}

@media only screen and (width <= 68.75em) {
  .modal__img {
    top: -75.1rem;
  }
}

@media only screen and (width <= 46.875em) {
  .modal__img {
    width: 62rem;
    height: 46.6165rem;
    top: -70.9083rem;
    left: -31rem;
  }
}

@media only screen and (width <= 35em) {
  .modal__img {
    width: 40rem;
    height: 30rem;
    top: -74.75rem;
    left: -20rem;
  }
}

@media only screen and (width <= 28.125em) {
  .modal__img {
    top: -74.75rem;
  }
}

.gallery__btn {
  color: #1e1e1e;
  height: 8.4rem;
  width: 4.2rem;
  cursor: pointer;
  z-index: 999;
  background: #ffffffb3;
  border: none;
  border-radius: .6rem;
  font-family: inherit;
  font-size: 4.2rem;
  line-height: 0;
  position: relative;
}

@media only screen and (width <= 46.875em) {
  .gallery__btn {
    height: 6.72rem;
    width: 3.36rem;
  }
}

@media only screen and (width <= 28.125em) {
  .gallery__btn {
    height: 6rem;
    width: 3rem;
  }
}

.gallery__btn--left {
  top: -95rem;
  left: -49.3rem;
  transform: translate(-50%, -50%);
}

@media only screen and (width <= 90em) {
  .gallery__btn--left {
    top: -82.65rem;
    left: -40.875rem;
  }
}

@media only screen and (width <= 68.75em) {
  .gallery__btn--left {
    top: -106.95rem;
  }
}

@media only screen and (width <= 46.875em) {
  .gallery__btn--left {
    top: -99.1rem;
    left: -34.5rem;
  }
}

@media only screen and (width <= 35em) {
  .gallery__btn--left {
    top: -94.85rem;
    left: -22.5rem;
  }
}

@media only screen and (width <= 28.125em) {
  .gallery__btn--left {
    top: -94.49rem;
  }
}

.gallery__btn--right {
  top: -103.4rem;
  right: -45.1rem;
  transform: translate(50%, -50%);
}

@media only screen and (width <= 90em) {
  .gallery__btn--right {
    top: -91.15rem;
    right: -36.675rem;
  }
}

@media only screen and (width <= 68.75em) {
  .gallery__btn--right {
    top: -115.45rem;
  }
}

@media only screen and (width <= 46.875em) {
  .gallery__btn--right {
    top: -105.9rem;
    right: -31rem;
  }
}

@media only screen and (width <= 35em) {
  .gallery__btn--right {
    top: -100.7rem;
    right: -19.5rem;
  }
}

@media only screen and (width <= 28.125em) {
  .gallery__btn--right {
    top: -100.45rem;
  }
}

.close-modal {
  cursor: pointer;
  z-index: 9999;
  width: 4.2rem;
  height: 4.2rem;
  color: #fff;
  z-index: 999;
  position: relative;
  top: -130.35rem;
  right: -47.2rem;
}

@media only screen and (width <= 90em) {
  .close-modal {
    top: -114.15rem;
    right: -39.775rem;
  }
}

@media only screen and (width <= 68.75em) {
  .close-modal {
    top: -138.45rem;
  }
}

@media only screen and (width <= 46.875em) {
  .close-modal {
    top: -127rem;
    right: -34rem;
  }
}

@media only screen and (width <= 35em) {
  .close-modal {
    top: -114rem;
    right: -22rem;
  }
}

@media only screen and (width <= 28.125em) {
  .close-modal {
    right: -20.5rem;
  }
}

.blur-background {
  content: "";
  width: 100vw;
  height: 100%;
  -webkit-backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  z-index: 99;
  background-color: #0006;
  transition: all .4s linear;
  position: absolute;
}

@media only screen and (width <= 59.375em) {
  .contact__centered {
    grid-template-columns: 1fr 60rem 1fr;
    display: grid;
  }
}

@media only screen and (width <= 28.125em) {
  .contact__centered {
    grid-template-columns: 1fr 40rem 1fr;
  }
}

@media only screen and (width <= 59.375em) {
  .contact__full {
    grid-area: 2 / 1 / span 1 / span 3;
    margin-bottom: 3rem;
  }

  .contact__heading {
    width: 32rem;
    grid-column: 2 / span 1;
    justify-self: center;
    display: flex;
  }
}

.contact__text-box {
  width: 42rem;
  flex-direction: column;
  font-size: 1.8rem;
  line-height: 1.8;
  display: flex;
}

@media only screen and (width <= 59.375em) {
  .contact__text-box {
    width: 32rem;
    grid-column: 2 / span 1;
  }
}

.contact__text-box .contact__item {
  gap: 1.2rem;
  padding: 1.2rem 0;
}

.iframe__box {
  width: 50%;
  height: 70.833rem;
  border-radius: 0;
  outline: .4rem outset #b7b7b7;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (width <= 59.375em) {
  .iframe__box {
    top: unset;
    right: unset;
    width: 60rem;
    z-index: 1;
    background-color: #e4e4e4;
    margin: 0 auto;
    position: relative;
  }
}

@media only screen and (width <= 35em) {
  .iframe__box {
    width: 55rem;
  }
}

@media only screen and (width <= 28.125em) {
  .iframe__box {
    width: 40rem;
  }
}

.form {
  flex-direction: column;
  gap: .9rem;
  transition: all 5s linear;
  display: flex;
}

.form__content-wrapper {
  width: 120rem;
  height: 75rem;
  color: #1e1e1e;
  background-color: #e4e4e4;
  border-radius: .6rem;
  outline: .5rem outset #b7b7b7;
  display: flex;
  overflow: hidden;
}

@media only screen and (width <= 90em) {
  .form__content-wrapper {
    height: 71rem;
    width: 113.605rem;
  }
}

@media only screen and (width <= 59.375em) {
  .form__content-wrapper {
    width: 66.27rem;
  }
}

@media only screen and (width <= 35em) {
  .form__content-wrapper {
    height: 68.32rem;
    width: 55rem;
  }
}

@media only screen and (width <= 28.125em) {
  .form__content-wrapper {
    height: 71.32rem;
    width: 40rem;
  }
}

.form__container {
  justify-content: space-between;
  display: flex;
}

.form__text-box {
  width: 70rem;
  height: 75rem;
  flex-direction: column;
  padding: 4rem;
  font-size: 1.8rem;
  display: flex;
}

@media only screen and (width <= 90em) {
  .form__text-box {
    height: 71rem;
    width: 66.27rem;
  }
}

@media only screen and (width <= 35em) {
  .form__text-box {
    height: 68.32rem;
    width: 55rem;
    padding: 2.66rem;
  }
}

@media only screen and (width <= 28.125em) {
  .form__text-box {
    height: 71.32rem;
    width: 40rem;
  }
}

.form__head {
  justify-content: space-between;
  margin-bottom: 8rem;
}

@media only screen and (width <= 90em) {
  .form__head {
    margin-bottom: 4rem;
  }
}

.form__heading {
  margin: 0;
}

.form__label {
  letter-spacing: .07rem;
  backface-visibility: hidden;
  font-size: 2.2rem;
  font-weight: 700;
}

.form:last-child {
  gap: .9rem;
}

.form__btn-container {
  height: 7.2rem;
  justify-content: center;
}

.form__img-box, .form__img {
  width: 50rem;
  height: 75rem;
  overflow: hidden;
}

@media only screen and (width <= 90em) {
  .form__img-box, .form__img {
    width: 47.335rem;
    height: 71rem;
  }
}

@media only screen and (width <= 59.375em) {
  .form__img-box, .form__img {
    display: none;
  }
}

.input {
  height: 4rem;
  border: none;
  border-radius: .6rem;
  outline: .3rem outset #b7b7b7;
  padding: .6rem;
  font-size: 1.6rem;
}

.input__container {
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
  display: flex;
}

.input__message {
  height: 19.2rem;
  resize: none;
  word-wrap: break-word;
  font-family: inherit;
}

.input:focus {
  outline: .5rem outset #b22a2a;
}

.btn--form {
  width: 88%;
  color: #fff;
  background-color: #000;
  align-self: center;
}

.btn--form:hover {
  width: 93%;
}

.submit-message {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.form__number {
  color: #1e1e1e;
  font-size: 2.2rem;
}

.form__error-list {
  flex-direction: column;
  gap: 1.2rem;
  list-style: none;
  display: flex;
}

.form__li {
  align-items: center;
  gap: 1.2rem;
  display: flex;
}

.form__icon {
  width: 2.4rem;
  height: 2.4rem;
}

.form__refresh {
  cursor: pointer;
  width: 3.6rem;
  height: 3.6rem;
  transition: all .2s ease-in-out;
}

.form__refresh:hover {
  backface-visibility: hidden;
  transform: scale(1.07);
}

.hide {
  display: none !important;
}

.opacity0 {
  opacity: 0;
}

.footer {
  height: 24rem;
}

@media only screen and (width <= 68.75em) {
  .footer {
    height: auto;
  }
}

.footer .header__content-wrapper {
  align-items: center;
}

.container--big {
  width: 100vw;
  z-index: 99999999;
  border: none;
}

@media only screen and (width >= 125em) {
  .container--big {
    width: 160rem;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    margin: 0 auto;
  }
}

.container--big-nb {
  border: none;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

@media only screen and (width <= 59.375em) {
  .container {
    padding: 0 4rem;
  }
}

@media only screen and (width <= 35em) {
  .container {
    padding: 0 2.66rem;
  }
}

.section-hero {
  margin-bottom: 2rem;
}

@media only screen and (width <= 35em) {
  .section-hero {
    margin-bottom: 0;
  }
}

.section-featured {
  padding: 4rem 0;
}

.section-services {
  background-color: #e4e4e4;
}

.article-transport {
  color: #e6e6e6;
  background-color: #222;
}

.article-inspection {
  background-color: #e4e4e4;
}

.section-contact {
  color: #1e1e1e;
  background-color: #e4e4e4;
  position: relative;
}

.section-form {
  background-color: #222;
}

/*# sourceMappingURL=index.448ea13d.css.map */
